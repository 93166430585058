import { isDevMode } from '@angular/core';
import { ClientConfig } from '@plutus-realty/web-config/client';
import { generateFirebaseFactories } from '@plutus-realty/web-config/firebase';
import { environment } from './environment';

const devMode = isDevMode();

export const clientConfig: ClientConfig = {
  version: environment.version,

  firebase: {
    options: {
      apiKey: "AIzaSyCrDRL0Pt7WuR5XCtUMBaw99JOIkB0_7GM",
      authDomain: "plutus-realty.firebaseapp.com",
      databaseURL: "https://plutus-realty.firebaseio.com",
      projectId: "plutus-realty",
      storageBucket: "plutus-realty.appspot.com",
      messagingSenderId: "1050497878310",
      appId: "1:1050497878310:web:9651202b33e10f683099e7"
    },
    factories: generateFirebaseFactories(devMode),
    VAPID: null,
  },

  oauth: {
    type: 'client',
    clientId: 'yBm8UEboKnFxIfeOkDG0pviw5deh62ir',
    origin: environment.oauth.origin,
    endpoints: {
      authorize: '/api/oauth/authorize',
      token: '/api/oauth/token'
    },
    redirectUri: environment.oauth.redirectUri
  },

  sso: environment.sso,

  splashDuration: 2000,
  seo: {
    baseTitle: 'Plutus Realty Management',
    defaultDescription: 'Best Real Estate Consultant and Marketer in New Town and Salt Lake, Kolkata'
  },

  contact: {
    email: 'info@plutusrealty.com',
    mobile: '+91 8777057870',
    whatsapp: {
      display: 'WhatsApp',
      link: 'https://wa.me/message/NVHDUT7237GSA1'
    },
    facebook: {
      display: 'Plutus Realty Management',
      link: 'https://www.facebook.com/Plutus-Realty-Management-112222913897330'
    },
    location: {
      link: 'https://www.google.com/maps?q=CE+Block(Newtown),+Action+Area+I,+Newtown,+New+Town,+West+Bengal+700156'
    }
  },
};