import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { listPageResolver, viewPageResolver, ViewPageResolverData } from '@plutus-realty/angular/residence/resolvers';
import { PageMetadata } from '@dd/angular-common';

export const RESIDENCES_ROUTES: Routes = [

  {
    path: 'residences/list',
    pathMatch: 'full',
    loadComponent: () => import('@plutus-realty/angular/residence/list/residence-list.page')
      .then(m => m.ResidenceListPageComponent),
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    data: {
      meta: {
        title: 'Residences',
        canonicalUrl: 'residences/list'
      }
    },
    resolve: {
      data: listPageResolver
    }
  },
  {
    path: 'residences/view/:slug/:id',
    pathMatch: 'full',
    loadComponent: () => import('@plutus-realty/angular/residence/view/residence-view.page')
      .then(m => m.ResidenceViewPageComponent),
    data: {
      hideGlobalContactForm: true,
      meta: (snap: ActivatedRouteSnapshot) => {
        const data = snap.data['data'] as ViewPageResolverData;
        const canonicalUrl = `/residences/view/id/${data.residence.id}`;

        const meta: PageMetadata = {
          title: data.residence.seo.title || data.residence.seo.url,
          description: data.residence.seo.description,
          canonicalUrl: canonicalUrl,
        };
        return meta;
      }
    },
    resolve: {
      data: viewPageResolver
    }
  },
  {
    path: 'residences',
    pathMatch: 'full',
    redirectTo: 'residences/list'
  }
];