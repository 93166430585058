import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MenuItem } from '@plutus-realty/angular/state/nav-menu';

@Component({
  standalone: true,
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrl: './vertical-menu.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    FontAwesomeModule,
    MatRippleModule
  ]
})
export class VerticalMenuComponent {
  public readonly items$ = input.required<MenuItem[]>({ alias: 'items' });
}