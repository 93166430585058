import { Route } from '@angular/router';
import { PUBLIC_ROUTES } from '../public/routes';
import { RESIDENCES_ROUTES } from '../residences/routes';
import { NotFoundPageComponent } from '@plutus-realty/angular/common/not-found/not-found.page';

export const appRoutes: Route[] = [
  ...PUBLIC_ROUTES,
  ...RESIDENCES_ROUTES,
  {
    path: '**',
    loadComponent: () => import('@plutus-realty/angular/common/not-found/not-found.page')
      .then(m => m.NotFoundPageComponent)
  }
];
