import { faBriefcase, faBuilding, faHome, faInfo, faList, faMessage, faShield } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from '@plutus-realty/angular/state/nav-menu';

export const publicMenu = [
  { id: 'home', text: 'Home', link: '/', icon: faHome },
  { id: 'residences', text: 'Residences', link: '/residences/list', icon: faBuilding },
  { id: 'our-services', text: 'Our Services', link: '/our-services', icon: faBriefcase },
  { id: 'about-us', text: 'About Us', link: '/about-us', icon: faInfo },
  { id: 'contact-us', text: 'Contact Us', link: '/contact-us', icon: faMessage },

] satisfies MenuItem[];


export const footerMenu = [
  ...publicMenu,
  { text: 'Privacy Policy', link: '/privacy-policy', icon: faShield },
  { text: 'Terms & Conditions', link: '/terms-and-conditions', icon: faList }
];