import '@plutus-realty/models/utils/superjson';

import { APP_BASE_HREF } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { SEOService, SEO_DEFAULT_CONFIG, provideRootServices } from '@dd/angular-common';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CLIENT_CONFIG } from '@plutus-realty/angular/dependency-injection';
import { RESIDENCE_LINK_BUILDER, ResidenceLinkBuilder } from '@plutus-realty/angular/residence/residence-link';
import { authFeature } from '@plutus-realty/angular/state/auth';
import { authEffects } from '@plutus-realty/angular/state/auth.effects';
import { navMenuFeature } from '@plutus-realty/angular/state/nav-menu';
import { uiFeature } from '@plutus-realty/angular/state/ui';
import { uiEffects } from '@plutus-realty/angular/state/ui.effects';
import { clientConfig } from '../client.config';
import { appRoutes } from './app.routes';

const residenceLinkBuilder: ResidenceLinkBuilder = (residence) => {
  return `/residences/view/${residence.seo.url}/${residence.id}`;
};

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },

    provideExperimentalZonelessChangeDetection(),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true
      })
    ),

    provideAnimations(),

    provideHttpClient(
      withFetch(),
      withInterceptors([]),
    ),

    provideRouter(appRoutes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
    ),
    { provide: CLIENT_CONFIG, useValue: clientConfig },
    { provide: SEO_DEFAULT_CONFIG, useValue: clientConfig.seo },
    provideRootServices(SEOService),

    provideStore(),
    provideStoreDevtools({
      name: 'plutus-realty-admin-web',
    }),
    provideState(authFeature),
    provideEffects(authEffects),

    provideState(uiFeature),
    provideEffects(uiEffects),

    provideState(navMenuFeature),

    {
      provide: RESIDENCE_LINK_BUILDER,
      useValue: residenceLinkBuilder
    }
  ],
};
