<div #appTopContact id="app-top-contact">
  <div>
    @for(contact of topContact; track contact.link) {
    <a [href]="contact.link" mat-ripple target="_blank">
      <fa-icon [icon]="contact.icon" class="w-6"></fa-icon>
      <span>{{contact.text}}</span>
    </a>
    }
  </div>
</div>

<div #appTopBar id="app-top-bar">
  <div>
    <div class="logo-container">
      <div class="flex gap-2">
        @if(sideBarState.showToggle$()) {
        <button class="icon-button" mat-ripple
                (click)="sideBarState.toggle()">
          <fa-icon [icon]="icons.faBars"></fa-icon>
        </button>
        }
        <a href="/">
          <img src="/assets/brand/logo.svg" class="h-8">
        </a>
      </div>
    </div>

    @if (nav.main.mode$() === 'horizontal') {
    <app-horizontal-menu [items]="nav.main.items" class="flex-none"></app-horizontal-menu>
    }

    <button class="filled-button flex gap-2 items-center flex-none">
      <fa-icon [icon]="icons.faPhone" class="w-4"></fa-icon>
      <span>Call Us Now!</span>
    </button>
  </div>


  @if(navigating$()) {
  <mat-progress-bar class="!absolute bottom-0 left-0" mode="indeterminate"> </mat-progress-bar>
  }
</div>

<mat-sidenav-container class="flex-1 !flex flex-col !overflow-visible">

  <mat-sidenav #appSidenav
               fixedInViewport [fixedTopGap]="sideBarState.offset$()"
               mode="side"
               [opened]="sideBarState.opened$()"
               [mode]="sideBarState.mode$()">
    <app-vertical-menu [items]="nav.main.items"></app-vertical-menu>
  </mat-sidenav>

  <mat-sidenav-content class="flex-1 !flex flex-col !overflow-visible">
    <router-outlet class="hidden [&_+_*]:flex-grow"></router-outlet>

    @if(contactForm.show$()) {
    <mat-divider></mat-divider>

    <div class="page 2xl:max-w-screen-2xl">

      <h2 class="font-bold text-xl text-center">Contact Us</h2>

      <div class="mt-4">
        <contact-form class="mt-4"></contact-form>
      </div>
    </div>
    }

    <mat-divider></mat-divider>

    <div class="page mx-auto
                flex flex-col gap-8 5xl:flex-row 5xl:flex-wrap justify-between">
      <div class="5xl:max-w-sm">
        <img src="/assets/brand/logo-text.svg" class="w-32">
        <p class="text-sm mt-4">
          Plutus Realty Management is a Kolkata-based real estate consultant and marketer providing professional real estate services.
          Our objective is to make the property-hunting process effortless, rapid and lucid for our clients.
          We have an exceptional team of legal advisors, market researchers, marketing agents and financial experts,
          with years of experience, guiding our clients every step of the way.
        </p>
      </div>

      <div id="footer-menu" class="5xl:flex-auto @container flex items-center">
        <div class="grid gap-8 max-w-max 5xl:ml-auto @md:grid-cols-2 @2xl:grid-cols-3">

          <div>
            <label class="font-bold px-4 mb-2">Menu</label>
            @for(item of nav.footer.menu; track item) {
            <a [routerLink]="item.link"
               class="block max-w-max text-button
                    hover:text-primary-800 focus:text-primary-800
                    p-1 px-4 text-sm"
               mat-ripple>
              {{item.text}}
            </a>
            }
          </div>

          <div>
            <label class="font-bold px-4 mb-2">Services</label>
            @for(service of nav.footer.services; track service) {
            <div class="p-1 px-4 text-sm">{{service}}</div>
            }
          </div>

          <div>
            <label class="font-bold px-4 mb-2">Contact Information</label>
            @for(item of nav.footer.contact; track item) {
            <a [routerLink]="item.link"
               class="max-w-max shaded-button text-sm p-2 px-4
                    hover:text-primary-800 focus:text-primary-800
                    flex gap-4 items-center"
               mat-ripple>
              <fa-icon [icon]="item.icon" class="w-5"></fa-icon>
              <span>{{item.text}}</span>
            </a>
            }
          </div>
        </div>



      </div>

    </div>

    <mat-divider></mat-divider>

    <div class="w-full max-w-screen-sm mx-auto p-4">
      <div class="grid gap-1 text-center text-sm font-semibold">
        <p>Copyright © 2021-2024 Plutus Realty Management. All Rights reserved.</p>
        <p>6.0.0</p>
        <p>Created by Plutus InfoTech</p>
      </div>
    </div>

  </mat-sidenav-content>

</mat-sidenav-container>