import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuItem } from '@plutus-realty/angular/state/nav-menu';

@Component({
  standalone: true,
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrl: './horizontal-menu.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule
  ]
})
export class HorizontalMenuComponent {
  public readonly items$ = input.required<MenuItem[]>({ alias: 'items' });

  removeFocus() {
    (document.activeElement as HTMLElement).blur();
  }
}