import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, HostBinding, INJECTOR, OnInit, computed, effect, inject, signal, untracked, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { ActivationEnd, Router, RouterModule } from '@angular/router';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition, faBars, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { COMMON_IMPORTS } from '@plutus-realty/angular/common-imports';
import { ContactFormComponent } from '@plutus-realty/angular/common/contact-form/contact-form.component';
import { CLIENT_CONFIG } from '@plutus-realty/angular/dependency-injection';
import { ScreenSize, sidebarActions, uiActions, uiFeature } from '@plutus-realty/angular/state/ui';
import { isBrowser } from '@plutus-realty/angular/utilities/platform-type';
import { map } from 'rxjs';
import { HorizontalMenuComponent } from '../navigation/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from '../navigation/vertical-menu/vertical-menu.component';
import { footerMenu, publicMenu } from './menu-items';

@Component({
  standalone: true,
  selector: 'plutus-realty-web-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    COMMON_IMPORTS,
    MatSidenavModule,
    RouterModule,
    ContactFormComponent,
    MatProgressBarModule,
    HorizontalMenuComponent,
    VerticalMenuComponent
  ],
})
export class AppComponent implements OnInit {
  private readonly client = inject(CLIENT_CONFIG);
  private readonly store = inject(Store);
  private readonly dRef = inject(DestroyRef);
  private readonly injector = inject(INJECTOR);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);

  private readonly isBrowser = isBrowser();

  @HostBinding() private readonly class = 'flex flex-col min-h-dvh';

  protected readonly icons = {
    faPhone,
    faEnvelope,
    faWhatsapp,
    faFacebookF,
    faBars
  } as const;

  protected readonly topContact = (() => {
    const buttons: {
      icon: IconDefinition,
      text: string;
      link: string;
      target: '_blank' | '_self';
    }[] = [];

    if (this.client.contact?.mobile)
      buttons.push({
        icon: this.icons.faPhone,
        text: this.client.contact.mobile,
        link: `tel:${this.client.contact.mobile}`,
        target: '_self'
      });

    if (this.client.contact?.email)
      buttons.push({
        icon: this.icons.faEnvelope,
        text: this.client.contact.email,
        link: `mailto:${this.client.contact.email}`,
        target: '_self'
      });

    if (this.client.contact?.whatsapp)
      buttons.push({
        icon: this.icons.faWhatsapp,
        text: this.client.contact.whatsapp.display,
        link: this.client.contact.whatsapp.link,
        target: '_blank'
      });

    if (this.client.contact?.facebook)
      buttons.push({
        icon: this.icons.faFacebookF,
        text: this.client.contact.facebook.display,
        link: this.client.contact.facebook.link,
        target: '_blank'
      });

    return buttons;

  })();

  private readonly screenSize$ = this.store.selectSignal(uiFeature.selectScreenSize);
  protected readonly sideBarState = (() => {
    const state$ = this.store.selectSignal(uiFeature.selectSidebar);

    const mode$ = computed(() => state$().visible ? 'side' : 'over');
    const opened$ = computed(() => state$().expanded);
    const showToggle$ = computed(() => mode$() === 'over');
    const offset$ = this.store.selectSignal(uiFeature.selectVerticalOffset);

    const toggle = () => this.store.dispatch(sidebarActions.toggleExpansion());

    return { mode$, opened$, showToggle$, offset$, toggle } as const;
  })();
  protected readonly navigating$ = this.store.selectSignal(uiFeature.selectNavigating);

  private readonly appSidenav$ = viewChild.required<MatSidenav>('appSidenav');
  private readonly appTopContact = viewChild.required<ElementRef<HTMLDivElement>>('appTopContact');
  private readonly appTopBar = viewChild.required<ElementRef<HTMLDivElement>>('appTopBar');

  protected readonly nav = (() => {

    const smallScreenSizes: Set<ScreenSize> = new Set(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']);
    const smallScreen$ = computed(() => smallScreenSizes.has(this.screenSize$()));


    const main = (() => {
      const mode$ = computed(() => smallScreen$() ? 'vertical' : 'horizontal');
      const items = publicMenu;

      return { items, mode$ } as const;
    })();

    return {
      main,
      footer: {
        menu: footerMenu,
        services: [
          'Real Estate Marketers',
          'Real Estate Investment',
          'Legal Services',
          'Commercial Property',
          'Residential Property',
          'Industrial Property'
        ],
        contact: this.topContact
      }
    } as const;
  })();

  protected readonly contactForm = (() => {
    const show$ = signal(true);

    this.router.events
      .pipe(
        map(event => {
          if (event instanceof ActivationEnd) {
            const snap = event.snapshot;
            if (snap.component) {
              const hideContactForm = snap.data?.['hideGlobalContactForm'] ?? false;
              show$.set(!hideContactForm);

            }
          }
        }),
        takeUntilDestroyed()
      ).subscribe();

    return {
      show$
    } as const;

  })();

  private updateVerticalOffet() {

    if (!this.isBrowser)
      return;

    const atc = this.appTopContact().nativeElement;
    const atb = this.appTopBar().nativeElement;

    const atch$ = signal(atc.clientHeight);
    const atbh$ = signal(atb.clientHeight);
    const scroll$ = signal(window.scrollY);
    const offset$ = computed(() => {
      const atch = Math.max(0, atch$() - scroll$());
      const atbh = atbh$();
      return atch + atbh;
    });

    const heightObserver = new ResizeObserver((records) => {
      records.forEach(r => {
        switch (r.target) {
          case atb: {
            atbh$.set(r.contentRect.height);
          } break;
          case atc: {
            atch$.set(r.contentRect.height);
          } break;
        }
      });
    });

    const scrollListener = () => {
      const travel = window.scrollY;
      if (travel > 256)
        return;
      scroll$.set(travel);
    };

    heightObserver.observe(atc, { box: 'border-box' });
    heightObserver.observe(atb, { box: 'border-box' });
    window.addEventListener('scroll', scrollListener);
    this.dRef.onDestroy(() => heightObserver.disconnect());
    this.dRef.onDestroy(() => window.removeEventListener('scroll', scrollListener));

    effect(() => {
      const offset = offset$();
      this.store.dispatch(uiActions.updateVerticalOffset({ verticalOffset: offset }));
    }, { allowSignalWrites: true, injector: this.injector });
  }

  private updateSidebarIfNeeded() {

    effect(() => {
      const mode = this.nav.main.mode$();

      if (mode === 'vertical') {
        this.store.dispatch(sidebarActions.contract());
        this.store.dispatch(sidebarActions.hide());
      }
      else {
        this.store.dispatch(sidebarActions.contract());
        this.store.dispatch(sidebarActions.show());
      }

    }, { allowSignalWrites: true, injector: this.injector });

    this.appSidenav$().closedStart
      .pipe(takeUntilDestroyed(this.dRef))
      .subscribe(() => {
        this.store.dispatch(sidebarActions.contract());
      });

    effect(() => {
      this.navigating$();
      if (untracked(this.sideBarState.mode$) === 'over') {
        this.appSidenav$().close();
      }
    }, { injector: this.injector });
  }

  ngOnInit() {
    this.updateVerticalOffet();
    this.updateSidebarIfNeeded();
  }
}
