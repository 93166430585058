import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResolveFn } from '@angular/router';
import { TRPC_API } from '@plutus-realty/angular/api/trpc.api';
import { SnackbarComponent } from '@plutus-realty/angular/common/notifications/snackbar';
import { ResidenceSearchOutput } from '@plutus-realty/models/residence';

export interface HomePageRouteData {
  featuredResidences: ResidenceSearchOutput[];
}

export const homePageResolver: ResolveFn<HomePageRouteData> = async () => {
  const trpc = inject(TRPC_API);
  const snackbar = inject(MatSnackBar);

  try {
    const featuredResidences = await trpc.residence.list.query({
      index: 'relevance',
      featured: true
    }).then(page => page.data);

    return {
      featuredResidences
    };
  }
  catch (e) {
    SnackbarComponent.forError(snackbar, e);
    throw e;
  }
};