import { BreakpointObserver } from '@angular/cdk/layout';
import { inject, isDevMode } from '@angular/core';
import { EventType, Router, Scroll } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { EMPTY, distinctUntilChanged, filter, first, map, skipUntil, switchMap } from 'rxjs';
import type { ScreensConfig } from 'tailwindcss/types/config';
import { TW_THEME } from '../dependency-injection';
import { isServer } from '../utilities/platform-type';
import { screenSizes, uiActions } from './ui';

export const uiEffects = {
  validateScreenDeclarations: createEffect(
    () => {
      if (isServer() || !isDevMode())
        return EMPTY;

      const screens = inject(TW_THEME).screens as ScreensConfig;
      const themeSizes = Object.keys(screens);

      validateScreenDeclarations(themeSizes, screenSizes);

      return EMPTY;
    },
    { functional: true, dispatch: false }),
  observeBreakpoints: createEffect(
    () => {
      if (isServer())
        return EMPTY;

      const screens = inject(TW_THEME).screens as Record<string, string>;
      const bo = inject(BreakpointObserver);

      const breakpoints = Object.fromEntries(
        screenSizes.map(size => [size, `(min-width: ${screens[size]})`])
      );

      const reverse = [...screenSizes].reverse();

      return bo.observe([...Object.values(breakpoints)])
        .pipe(
          map(observed => {
            return reverse.find(size => {
              const bp = breakpoints[size];
              return observed.breakpoints[bp] === true;
            }) || 'xs';

          }),
          distinctUntilChanged(),
          map(size => uiActions.updateScreenSize({ size }))
        );
    },
    { functional: true }
  ),
  watchRouterNavigation: createEffect(
    () => {
      const router = inject(Router);

      return router.events.pipe(
        map(event => event instanceof Scroll ? event.routerEvent : event),
        map(event => {
          switch (event.type) {
            case EventType.NavigationStart:
            case EventType.ResolveStart: return true;
            case EventType.NavigationEnd:
            case EventType.NavigationCancel:
            case EventType.NavigationError: return false;
            default: return undefined;
          }
        }),
        filter((nav): nav is boolean => typeof nav === 'boolean'),
        distinctUntilChanged(),
        map(navigating => uiActions.updateNavigationState({ navigating }))
      );
    },
    { functional: true }
  ),
  hideSplashScreen: createEffect(
    () => {
      if (isServer())
        return EMPTY;

      const router = inject(Router);

      return router.events
        .pipe(
          filter(e => e.type === EventType.ActivationStart),
          first(),
          map(() => uiActions.hideSplashScreen())
        );
    },
    { functional: true }
  )
};

function validateScreenDeclarations(
  tailwind: readonly string[],
  screens: readonly string[]
) {

  const sets = {
    screens: new Set(screens),
    tailwind: new Set(tailwind),
  };

  for (const size of tailwind) {
    if (!sets.screens.has(size)) {
      throw new Error(`'${size}' is declared in tailwind config, but not declared in typescript screens`);
    }
  }

  for (const size of screens) {
    if (!sets.tailwind.has(size)) {
      throw new Error(`'${size}' is declared in typescript screen, but not declared in tailwind config`);
    }
  }
}
