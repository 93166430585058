import { effect, inject, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { User as FirebaseUser, getIdToken, getIdTokenResult, onIdTokenChanged } from 'firebase/auth';
import { EMPTY, filter, Observable, switchMap } from 'rxjs';
import { FirebaseService } from '../services/firebase';
import { isBrowser } from '../utilities/platform-type';
import { authActions, authFeature, checkIfSettingUp, mapTokenToUser } from './auth';

export const authEffects = {
  logUserInDevMode: createEffect(
    () => {
      if (!isDevMode() || !isBrowser())
        return EMPTY;

      const store = inject(Store);
      const firebase = inject(FirebaseService);

      const user$ = store.selectSignal(authFeature.selectUser);

      effect(async () => {
        const user = user$();
        if (user) {
          const token = await getIdToken(firebase.auth.currentUser!);
          console.debug(user);
          console.debug(token);
        }
      });

      return EMPTY;
    },
    { functional: true, dispatch: false }
  ),

  onTokenChange: createEffect(
    () => {
      const firebase = inject(FirebaseService);

      const user$ = new Observable<FirebaseUser | null>(subscriber => {
        onIdTokenChanged(firebase.auth, user => subscriber.next(user));
      });

      return user$
        .pipe(
          switchMap(async firebaseUser => {
            if (firebaseUser) {
              const tokenResult = await getIdTokenResult(firebaseUser);
              const user = mapTokenToUser(firebaseUser, tokenResult);

              if (checkIfSettingUp.tokenResult(tokenResult))
                return null;

              return authActions.updateUser({
                payload: { user }
              });
            }
            else {
              return authActions.updateUser({
                payload: { user: null }
              });
            }
          }),
          filter(action => !!action)
        );
    },
    { functional: true }
  ),

  onSignOut: createEffect(() => {
    const actions$ = inject(Actions);
    const router = inject(Router);

    return actions$.pipe(
      ofType(authActions.signOut),
      switchMap(async (payload) => {

        if (payload.revoked)
          location.reload();
        else {
          await router.navigateByUrl('/', {
            info: {
              source: authActions.signOut.type
            }
          });
        }
      })
    );
  }, { functional: true, dispatch: false })
};