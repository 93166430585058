import { FirebaseOptions, initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';

export function generateFirebaseFactories(devMode: boolean) {
  const firebaseFactories = {
    app: (config: FirebaseOptions, tenantId?: string) => {
      return initializeApp(config, tenantId);
    },
    auth: (app: FirebaseApp) => {
      const auth = getAuth(app);
      // check for dev mode, and if emulator config is set by previous hmr
      if (devMode && !auth.emulatorConfig) {
        connectAuthEmulator(auth, 'http://localhost:5001', { disableWarnings: true });
      }
      return auth;
    },
    storage: (app: FirebaseApp) => {
      const storage = getStorage(app);
      if (devMode) {
        connectStorageEmulator(storage, 'localhost', 5004);
      }
      return storage;
    },
    storageOrigin: () => devMode ?
      'http://localhost:5004' :
      'https://firebasestorage.googleapis.com',
    messaging: (app: FirebaseApp) => getMessaging(app)
  } as const;

  return firebaseFactories;
}