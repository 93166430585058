<div class="rounded-3xl p-2 grid gap-1">
  @for(item of items$(); track item) {
  <a class="relative rounded-2xl p-2 px-4 transition-all 
    font-semibold text-sm
    flex gap-4 items-center
    hover:bg-primary-700/10"
     [routerLink]="item.link"
     routerLinkActive="!bg-primary-700/30"
     [routerLinkActiveOptions]="{exact: true}"
     mat-ripple>
    @if (item.icon; as icon) {
    <fa-icon [icon]="icon" class="absolute"></fa-icon>
    }
    <span class="ml-10">{{item.text}}</span>
  </a>
  }
</div>