import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params, ResolveFn, Router } from '@angular/router';
import { Page } from '@plutus-realty/models/pagination';
import { BHK_VALUES, Residence, ResidenceSearchOutput } from '@plutus-realty/models/residence';
import { numberEnum } from '@plutus-realty/models/utils/numeric-enum';
import { ResidenceSearchInput } from '@plutus-realty/models/validators/residence';
import { z } from 'zod';
import { TRPC_API } from '../api/trpc.api';
import { SnackbarComponent } from '../common/notifications/snackbar';

export interface ListPageResolverData {
  page: Page<ResidenceSearchOutput>;
  params: Params;
  input: ResidenceSearchInput;
}

export interface ViewPageResolverData {
  residence: Residence;
}
export const listPageResolver: ResolveFn<ListPageResolverData> = async (route) => {
  const trpc = inject(TRPC_API);
  const snackbar = inject(MatSnackBar);



  try {
    const params = listQueryParams.parse(route.queryParams);

    const input: ResidenceSearchInput = {
      index: 'relevance',
      query: params.query,

      featured: null,
      bedrooms: params.bedrooms,
      maxPrice: params.maxPrice,
      resale: null,
      status: [],
      published: true,

      pagination: {
        number: params.page - 1,
        size: 24,
      },
    };

    const page = await trpc.residence.list.query(input);

    return {
      page,
      input,
      params: route.queryParams
    };
  }
  catch (err) {
    SnackbarComponent.forError(snackbar, err);
    throw err;
  }
};

export const viewPageResolver: ResolveFn<ViewPageResolverData> = async (route) => {
  const trpc = inject(TRPC_API);
  const router = inject(Router);

  try {
    const params = getParams.parse(route.params);

    const residence = await trpc.residence.get.query({ id: params.id });

    return { residence };
  }
  catch (e) {
    router.navigateByUrl(`/error-404`, { skipLocationChange: true });
    throw e;
  }

};

const listQueryParams = z.strictObject({
  query: z.string().trim().catch(''),
  page: z.coerce.number().min(1).catch(1),
  bedrooms: z.string()
    .transform(v => v.split(','))
    .pipe(z.array(z.coerce.number().superRefine(numberEnum(BHK_VALUES))))
    .catch([]),
  maxPrice: z.coerce.number().max(3_00_00_000).nullable().catch(null),
});

const getParams = z.strictObject({
  slug: z.string().trim(),
  id: z.string().trim()
});
