import { Routes } from '@angular/router';
import { homePageResolver } from './home/resolver';

export const PUBLIC_ROUTES: Routes = [
  {
    path: 'about-us',
    pathMatch: 'full',
    loadComponent: () => import('./about-us/about-us.page')
      .then(m => m.AboutUsPageComponent),
    data: {
      meta: {
        title: 'About Us',
        canonicalUrl: 'about-us'
      }
    }
  },
  {
    path: 'contact-us',
    pathMatch: 'full',
    loadComponent: () => import('./contact-us/contact-us.page')
      .then(m => m.ContactUsPageComponent),
    data: {
      hideGlobalContactForm: true,
      meta: {
        title: 'Contact Us',
        canonicalUrl: 'contact-us'
      }
    }
  },
  {
    path: 'home',
    pathMatch: 'full',
    loadComponent: () => import('./home/home.page').then(m => m.HomePageComponent),
    resolve: {
      data: homePageResolver
    }
  },
  {
    path: 'our-services',
    pathMatch: 'full',
    loadComponent: () => import('./our-services/our-services.page')
      .then(m => m.OurServicesPageComponent),
    data: {
      meta: {
        title: 'Our Services',
        canonicalUrl: 'our-services'
      }
    }
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./home/home.page').then(m => m.HomePageComponent),
    resolve: {
      data: homePageResolver
    }
  },
];