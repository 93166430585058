import { isDevMode } from '@angular/core';

const configs = {
  development: {
    oauth: {
      origin: 'http://localhost:8082',
      redirectUri: 'http://localhost:8084/oauth/callback'
    },
    sso: {
      domain: 'localhost'
    },
    version: '6.0.0'
  },
  production: {
    oauth: {
      origin: 'https://account.plutusrealty.com',
      redirectUri: 'https://plutusrealty.com/oauth/callback'
    },
    sso: {
      domain: 'plutusrealty.com',
    },
    version: '6.0.0'
  }
} as const;

export const environment = isDevMode() ?
  configs.development :
  configs.production;